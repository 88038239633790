<template>
  <div class="d-flex flex-column align-items-center">
    <div class="col-12 text-center">
      <b-card-title>
        {{ $t('Do you have more questions?') }}
      </b-card-title>
    </div>
    <div class="col-sm-8 col-md-4 text-center">
      <b-card-text class="text-center">
        {{ $t('If you do not find the answer to your question, you can send an email to') }} <b-link href="mailto:info@when.org.gr">
          info@when.org.gr
        </b-link>
      </b-card-text>
    </div>
  </div>
</template>

<script>
import {
  BCardText,
  BCardTitle,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BCardTitle,
    BLink,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
