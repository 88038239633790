<template>
  <div>
    <page-header class="mb-2">
      <template #title>
        {{ $t('Frequently asked questions') }}
      </template>
      <template #message>
        {{ $t('Find here answers to your questions') }}
      </template>
    </page-header>

    <section
      id="knowledge-base-content"
      class="mb-3"
    >

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in filteredKB"
          :key="item.id"
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            :img-src="item.img"
            :img-alt="item.img.slice(5)"
            img-top
            @click="$router.push({ name: 'pages-knowledge-base-category', params: { category: item.category } })"
          >
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.desc }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            {{ $t('Search result not found') }}
          </h4>
        </b-col>
      </b-row>
    </section>
    <section v-if="!knowledgeBaseSearchQuery">
      <have-questions />
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
} from 'bootstrap-vue'
import HaveQuestions from '@mentoring-platform/views/components/blocks/Help/HaveQuestions.vue'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    HaveQuestions,
    PageHeader,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
    }
  },
  computed: {
    kb() {
      return [
        {
          id: 1,
          category: 'mentoring-in-general',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/pages/help/FAQ_mentoring.png'),
          title: this.$t('Mentoring in general'),
          desc: this.$t('What mentoring is and how it can help you.'),
        },
        {
          id: 2,
          category: 'for-mentors',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/pages/help/FAQ_mentors.png'),
          title: this.$t('For mentors'),
          desc: this.$t('Whatever you need to know if you want to support another woman.'),
        },
        {
          id: 3,
          category: 'for-mentees',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/pages/help/FAQ_mentees.png'),
          title: this.$t('For mentees'),
          desc: this.$t('Whatever you need to know to found the support you need.'),
        },
      ]
    },
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
